import React from "react"
import { Router, Redirect, Location } from "@reach/router"
import PrivateRoute from "./../../components/privateRoute.js"
import UserList from "./../../components/users"
import Requests from "./../../components/requests"
import { Helmet } from "react-helmet"

export default function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name='description' content='Namami Gange Dashboard!' />
        <meta name='owner' content='https://www.qcin.org/' />
        <meta name='developer' content='https://dataorc.in/' />
        <title>User | Namami Gange Dashboard</title>
      </Helmet>

      <Location>
        {({ location }) => (
          <Router basepath="/app" location={location}>
            
            <PrivateRoute path="/users" component={UserList} />
            <PrivateRoute path="/requests" component={Requests} />
            
          </Router>
        )}
      </Location>
    </>
    
  )
}
