import React, { useEffect, useState } from "react";
import "./users.css";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Typography,
Input, InputAdornment, ThemeProvider, Container, FormControl } from '@material-ui/core';
import { _queryStringify, handleResponse } from "./../utils/xhr.js";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import AddUser from './addUser.js';
import Snackbar from "./../shared/Snackbar";
import Spinner from "./../shared/Spinner";
import { getUser } from './../utils/authorizations';
import Cookies from "js-cookie"
import theme from '../theme';
import Header from '../components/Header';
import CopyRight from '../components/CopyRight';
import Link from '../components/Link';


const useStyles = makeStyles((theme) => ({
  textFields: {
    width: "400px",
    margin: "20px 0"
  },
  root: {
    width: '100%',
    marginTop: "20px"
  },
  container: {
    maxHeight: 440,
  },
  borderedTable: {
    width: "50%",
    margin: 20,
    "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)"
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    width: "50%"
  }
}));

const columns = [
  { id: 'full_name', label: 'Name', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'role', label: 'Role', minWidth: 170 },
  { id: "update", label: "", minWidth: 50 },
];

function UserList() {
//   const userNew = getUser()
  let user_info = getUser().sub
  console.log(user_info)
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(null);
  const [userChatDetails, setUserChatDetails] = useState({});
  const [snackbarData, setSnackbarData] = useState({
    snackMessage: "Empty message",
    snackbarType: "",
  });
  const [query, setQuery] = useState("");
  const [pageType, setPageType] = useState("users");
  const [user, setUser] = useState({});
  const [rowOpen, setRowOpen] = useState(null);

  const getUserAccounts = () => {
    setLoading(true);
    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-users`

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("access_token_cookie")}`,
      }
    })
    .then(handleResponse)
    .then((data) => {
      setUsers(data);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      setSnackbarData((snackbarData) => ({
        snackMessage: "Sorry, could not get user accounts!",
        snackbarType: "error",
      }));
      setOpen(true);
      console.log(err);
    });
  };

  useEffect(() => {
    getUserAccounts();
  }, []);

  const _filterMembers = (member) => {
    var lowerCaseQuery = query.toLowerCase();
    return (member['full_name'].toLowerCase().indexOf (lowerCaseQuery) > -1 ||
            member['email'].indexOf (lowerCaseQuery) > -1);
  };

  const renderUserList = () => {
    var filteredMembers = users.filter(_filterMembers);
    if (filteredMembers.length === 0) {
      return (
        <div className={classes.root}
             style={{textAlign: "center"}}>
          No Data!
        </div>
      );
    }

    return (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell />
                {columns.map(renderTableColumns)}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredMembers.map(renderUserRow)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  const renderTableColumns = (column) => {
    return (
      <TableCell
        key={column.id}
        align={column.align}
        style={{ minWidth: column.minWidth }}
        >
        {column.label}
      </TableCell>
    );
  };

  const handleRowOpen = (row) => {
    setLoading(true);
    setRowOpen(row.id);
    setUserChatDetails(row);
    setLoading(false)
  };

  const handleRowClose = () => {
    setRowOpen(null);
    setUserChatDetails({});
  };

  const renderUserRow = (row) => {
    return (
      <React.Fragment>
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
          <TableCell>
            <IconButton aria-label="expand row"
                        size="small"
                        onClick={() => rowOpen == row.id ? handleRowClose() : handleRowOpen(row)}>
              {rowOpen == row.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            {row['full_name']}
          </TableCell>
          <TableCell>
            {row['email']}
          </TableCell>
          <TableCell>
            {row['role'].toUpperCase()}
          </TableCell>

          {(user_info?.role === 'super_admin' ||  user_info?.role === 'agency_admin') ?
          <TableCell>
            <EditIcon style={{cursor: "pointer"}} onClick={() => {setPageType('edit-user'); setUser(row);}}/>
          </TableCell> :
          null
          }
          
        </TableRow>
        {renderUserDetails(row)}
      </React.Fragment>
    );
  };

  const renderUserDetails = (row) => {
    return (
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={rowOpen == row.id} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6"
                          style={{margin: "20px"}}
                          component="div">
                User Details
              </Typography>
              <Table size="small"
                     className={classes.borderedTable}>
                <TableBody>
                  <TableRow key={userChatDetails.id}>
                    <TableCell align="center">
                      <b>Name</b>
                    </TableCell>
                    <TableCell component="th"
                               scope="row"
                               align="left">
                      {row.full_name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <b>Email</b>
                    </TableCell>
                    <TableCell align="left">
                      {row.email}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <b>Role</b>
                    </TableCell>
                    <TableCell align="left">
                      {row.role.toUpperCase()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <b>Registration ID</b>
                    </TableCell>
                    <TableCell align="left">
                      {row.registration_id}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <b>Phone Number</b>
                    </TableCell>
                    <TableCell align="left">
                      {row.phone_number}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <b>Address</b>
                    </TableCell>
                    <TableCell align="left">
                      {row.address}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <b>Active</b>
                    </TableCell>
                    <TableCell align="left">
                      {row.active}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <b>State</b>
                    </TableCell>
                    <TableCell align="left">
                      {row.state}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center">
                      <b>District</b>
                    </TableCell>
                    <TableCell align="left">
                      {row.district}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  };

  const validNumber = (id, v, error_msg="Please enter valid input") => {
    if (!/^-?\d+\.?\d*$/.test(v)) {
      setErrors((errors) => {
        const tempErrors = {...errors,
                            [id]: error_msg};
        return tempErrors;
      });
      return false;
    }

    setErrors((errors) => {
        const tempErrors = {...errors,
                            [id]: false};
        return tempErrors;
      });
    return true;
  };

  const renderPageContent = () => {
    if (pageType === 'users') {
      return (
        <div>
          <div style={{
                 display: "flex",
                 justifyContent:"space-between",
                 width: "90%",
                 margin: "0 auto 0 auto"
               }}>
            <FormControl fullWidth
                         className={classes.formControl}
                         variant="outlined">
              <Input
                id="input-with-icon-adornment"
                placeholder="Search"
                onChange={e => setQuery(e.target.value)}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    />
            </FormControl>
            {(user_info?.role === 'super_admin' ||  user_info?.role === 'agency_admin') ?
            <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={() => setPageType('add-user')}
            >Add User</Button> :
            null
            }
          </div>
          <div className="users__heading">
            <h3>Users</h3>
          </div>
          {renderUserList()}
        </div>
      );
    }
    if (pageType === 'add-user') {
      return (
        <AddUser
          type="add-user"
          getUserAccounts={getUserAccounts}
          setPageType={setPageType}
          getUserAccounts={getUserAccounts} />);
    }
    if (pageType === 'edit-user') {
      return (
        <AddUser
          type="edit-user"
          user={user}
          getUserAccounts={getUserAccounts}
          setPageType={setPageType} 
          getUserAccounts={getUserAccounts} />
      );
    }
    return null;
  };

  const { snackbarType, snackMessage } = snackbarData;
  return (
    <>
      <ThemeProvider theme={theme}>

        <Header isSelectBar={false} isTogglePage={true} page="users" height="120px" />

        <Box
            sx={{
            backgroundColor: '#d3d5e5',
            minHeight: '100%',
            pt: 3,
            pb: 1
            }}
            style={{background: '#d3d5e5'}}
        >
            <Container maxWidth={false}>
              <div className="tabelpane__content">
                {renderPageContent()}
                {loading && <Spinner />}
                <Snackbar
                  type={snackbarType}
                  open={open}
                  setOpen={setOpen}

                  snackMessage={snackMessage}
                />
              </div>
            </Container>
        </Box>
      
        <div style={{display: "flex", justifyContent: "center"}}>
          <Link to="/" style={{marginRight: 10}}> Home </Link>
          <CopyRight />
        </div>
      </ThemeProvider>
    </>

  );
}

export default UserList;
