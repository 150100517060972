import React, { useEffect, useState } from "react";
import "./users.css";
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Switch } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { _queryStringify, handleResponse } from "./../utils/xhr.js";
import { isEmpty } from "./../utils/string.js";
import Snackbar from "./../shared/Snackbar";
import Spinner from "./../shared/Spinner";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Cookies from "js-cookie"


const useStyles = makeStyles((theme) => ({
  textFields: {
    width: "400px",
    margin: "20px 0",
  },
}));

const filter = createFilterOptions();

const defaultProductNames = [
  {name: "product 1"},
  {name: "product 2"}
];

const defaultCompanyNames = [
  {name: "company 1"},
  {name: "company 2"}
];

function AddUser({
  type,
  user,
  getUserAccounts,
  setPageType,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [role, setRole] = useState("");
  const [permissions, setPermissions] = useState(["permission_1", "permission_2"]);
  const [customPermissions, setCustomPermissions] = useState([]);
  const [address, setAddress] = useState("");
  const [state, setState] = useState(null);
  const [states, setStates] = useState([]);
  const [district, setDistrict] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [active, setActive] = useState(true);

  const [productName, setProductName] = useState("");
  const [productSerialNumber, setProductSerialNumber] = useState("");
  const [businessAccountId, setBusinessAccountId] = useState("");
  const [id, setId] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    snackMessage: "Empty message",
    snackbarType: "",
  });
  const [productNames, setProductNames] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getStates()
    getRoles();
    getPermissions();
    setFullName(user?.full_name);
    setEmail(user?.email);
    setMobileNumber(user?.phone_number);
    setDesignation(user?.designation);
    setRole(user?.role);
    setCustomPermissions(user?.custom_permissions ? user?.custom_permissions : [])
    setAddress(user?.address)
    setState(user?.state)
    setDistrict(user?.district)
    setId(user?.id);
  }, [user]);

  const getStates = () => {
    setLoading(true);
    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-entities-based-on-user?${_queryStringify({type: "state"})}`


    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("access_token_cookie")}`,
      }
    })
      .then(handleResponse)
      .then((data) => {
        setStates(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarData((snackbarData) => ({
          snackMessage: "Sorry, could not get states!",
          snackbarType: "error",
        }));
        setOpen(true);
        console.log(err);
      });
  };

  const getDistricts = (state) => {
    setLoading(true);
    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-entities-based-on-user?${_queryStringify({type: "district", parent: state})}`

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("access_token_cookie")}`,
      }
    })
      .then(handleResponse)
      .then((data) => {
        setDistricts(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarData((snackbarData) => ({
          snackMessage: "Sorry, could not get districts!",
          snackbarType: "error",
        }));
        setOpen(true);
        console.log(err);
      });
  };

  const setStateValue = (state) => {
    setState(state)
    setDistrict(null)
    getDistricts(state)
  }

  const setDistrictValue = (district) => {
    setDistrict(district)
  }

  const getRoles = () => {
    setLoading(true);
    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-roles-based-on-user`


    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("access_token_cookie")}`,
      }
    })
      .then(handleResponse)
      .then((data) => {
        setRoles(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarData((snackbarData) => ({
          snackMessage: "Sorry, could not get roles!",
          snackbarType: "error",
        }));
        setOpen(true);
        console.log(err);
      });
  };

  const getPermissions = () => {
    setLoading(true);
    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-permissions-based-on-user`

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("access_token_cookie")}`,
      }
    })
      .then(handleResponse)
      .then((data) => {
        setPermissions(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarData((snackbarData) => ({
          snackMessage: "Sorry, could not get roles!",
          snackbarType: "error",
        }));
        setOpen(true);
        console.log(err);
      });
  };

  const validPhonenumber = (value) => {
    if (value
        && value.length == 10
        && value.match(/\d{10}/)) {
      setErrors((errors) => {
        const tempErrors = { ...errors, mobileNumber: false };
        return tempErrors;
      });
      return true;
    } else {
      setErrors((errors) => {
        const tempErrors = {
          ...errors,
          mobileNumber: "Please enter valid mobile number!",
        };
        return tempErrors;
      });
      return false;
    }
  };

  const validStateField = (value) => {
    if (isEmpty(value)) {
      if(['super_admin', 'agency_admin'].includes(role)){
        setErrors((errors) => {
          const tempErrors = { ...errors, state: false };
          return tempErrors;
        });
        return true;
      }
      else {
        setErrors((errors) => {
        const tempErrors = { ...errors, state: "Selected role needs to provide the state!" };
        return tempErrors;
      });
      return false;
      }
    } else {
      setErrors((errors) => {
        const tempErrors = { ...errors, state: false };
        return tempErrors;
      });
      return true;
    }
  };

  const validDistrictField = (value) => {
    if (isEmpty(value)) {
      if(['super_admin', 'agency_admin', 'state_level_user'].includes(role)){
        setErrors((errors) => {
          const tempErrors = { ...errors, district: false };
          return tempErrors;
        });
        return true;
      }
      else {
        setErrors((errors) => {
        const tempErrors = { ...errors, district: "Selected Role need to provide the district!" };
        return tempErrors;
      });
      return false;
      }
    } else {
      setErrors((errors) => {
        const tempErrors = { ...errors, district: false };
        return tempErrors;
      });
      return true;
    }
  };

  const validStringField = (
    field,
    value,
    error_message = "Field is required!"
  ) => {
    if (isEmpty(value)) {
      setErrors((errors) => {
        const tempErrors = { ...errors, [field]: error_message };
        return tempErrors;
      });
      return false;
    } else {
      setErrors((errors) => {
        const tempErrors = { ...errors, [field]: false };
        return tempErrors;
      });
      return true;
    }
  };

  const validDetails = () => {
    const validMobileNumber = validPhonenumber(mobileNumber);
    const validFullName = validStringField("fullName", fullName);
    const validEmail = validStringField("email", email);
    const validDesignation = validStringField("designation", designation);
    const validRole = validStringField("role", role);
    const validAddress = validStringField("address", address);
    const validState = validStateField(state);
    const validDistrict = validDistrictField(district);

    return (
      validMobileNumber &&
        validFullName &&
        validEmail &&
        validDesignation &&
        validRole && 
        validAddress &&
        validState && 
        validDistrict
    );
  };

  const addUser = () => {
    setLoading(true);
    let payload = {
        full_name: fullName,
        email: email,
        phone_number: mobileNumber,
        designation: designation,
        role: role,
        custom_permissions: customPermissions,
        address: address,
        state: state,
        district: district,
        active: active,
    }

    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/create-user`

    if (type === 'edit-user') {
        payload['id'] = id
        url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/update-user-details`
    }
    
    fetch(url, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${Cookies.get("access_token_cookie")}`,
      },
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then(handleResponse)
      .then((data) => {
        let success_msg = type === 'add-user' ? "User has been added!" : "User details has been updated!";
        setLoading(false);
        setSnackbarData((snackbarData) => ({
          snackMessage: success_msg,
          snackbarType: "success",
        }));
        setOpen(true);
        setFullName("");
        setEmail("");
        setMobileNumber("");
        setDesignation("");
        setRole("");
        setCustomPermissions([])
        setAddress("")
        setState(null)
        setDistrict(null)
        setPageType('users');
        getUserAccounts()
      })
      .catch((err) => {
        let error_msg = type === 'add-user' ? "Sorry, failed to add the user!" : "Sorry, failed to update the user!";
        setSnackbarData((snackbarData) => ({
          snackMessage: error_msg,
          snackbarType: "error",
        }));

        setOpen(true);
        setLoading(false);
        console.log(err);
      });
  };

  const handleSubmit = () => {
    if (validDetails()) {
      addUser();
    }
    //history.push("/dashboard");
  };

  const { snackbarType, snackMessage } = snackbarData;
  return (
    <div className="tabelpane__content">
      <div style={{display: "flex", justifyContent: "flex-start", cursor: "pointer"}}
           onClick={() => {setPageType("users"); getUserAccounts();}}>
        <ArrowBackIcon /> Back
      </div>
      <div className="users__heading">
        <h3>
          {type === 'add-user' ? "Create a User" : "Update User Details"}
        </h3>
      </div>

      <div className="users__questions">
        <TextField
          error={errors["fullName"] ? true : null}
          id="full_name"
          label="Full Name"
          variant="outlined"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          classes={{ root: classes.textFields }}
          helperText={errors["fullName"] ? errors["fullName"] : null}
        />
        <TextField
          error={errors["email"] ? true : null}
          id="email"
          label="Email"
          type="text"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          classes={{ root: classes.textFields }}
          helperText={errors["email"] ? errors["email"] : null}
        />
        <TextField
          error={errors["mobileNumber"] ? true : null}
          id="mobile_number"
          label="Mobile Number"
          type="number"
          variant="outlined"
          value={mobileNumber}
          inputProps={{ maxLength: 4 }}
          onChange={(e) => setMobileNumber(e.target.value)}
          classes={{ root: classes.textFields }}
          helperText={errors["mobileNumber"] ? errors["mobileNumber"] : null}
        />        

        <TextField
          error={errors["designation"] ? true : null}
          id="designation"
          label="Designation"
          type="text"
          variant="outlined"
          value={designation}
          onChange={(e) => setDesignation(e.target.value)}
          classes={{ root: classes.textFields }}
          helperText={errors["designation"] ? errors["designation"] : null}
        />
        <Autocomplete
          filterSelectedOptions
          id="role"
          options={roles}
          getOptionLabel={(option) => option.toUpperCase()}
          value={role ? role : null}
          onChange={(event, newValue) => {
            if(newValue){
                setRole(newValue);
            }
          }}
          className="business__autocomplete"
          renderInput={(params) => (
            <TextField {...params}
                       error={errors["role"] ? true : null}
                       label="Select Role"
                       variant="outlined"
                       helperText={errors["role"] ? errors["role"] : null}/>
          )}
        />
        <Autocomplete
          multiple
          filterSelectedOptions
          id="custom_permissions"
          options={permissions}
          getOptionLabel={(option) => option.toUpperCase()}
        //   value={role ? role : null}
          value={customPermissions}
          onChange={(event, newValue) => {
            if(newValue){
                setCustomPermissions(newValue);
            }
          }}
          className="business__autocomplete"
          renderInput={(params) => (
            <TextField {...params}
                       error={errors["custom_permissions"] ? true : null}
                       label="Select Custom Permissions"
                       variant="outlined"
                       helperText={errors["custom_permissions"] ? errors["custom_permissions"] : null}/>
          )}
        />

        <TextField
          error={errors["address"] ? true : null}
          id="address"
          label="Address"
          type="text"
          multiline
          rows={2}
          rowsMax={4}
          variant="outlined"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          classes={{ root: classes.textFields }}
          helperText={errors["address"] ? errors["address"] : null}
        />
        
        <Autocomplete
          filterSelectedOptions
          id="state"
          options={states}
          getOptionLabel={(option) => option.toUpperCase()}
          value={state ? state : null}
          onChange={(event, newValue) => {
            if(newValue){
                setStateValue(newValue);
            }
          }}
          className="business__autocomplete"
          renderInput={(params) => (
            <TextField {...params}
                       error={errors["state"] ? true : null}
                       label="Select State"
                       variant="outlined"
                       helperText={errors["state"] ? errors["state"] : null}/>
          )}
        />

        <Autocomplete
          filterSelectedOptions
          id="district"
          options={districts}
          getOptionLabel={(option) => option.toUpperCase()}
          value={district ? district : null}
          onChange={(event, newValue) => {
            if(newValue){
                setDistrictValue(newValue);
            }
          }}
          className="business__autocomplete"
          renderInput={(params) => (
            <TextField {...params}
                       error={errors["district"] ? true : null}
                       label="Select District"
                       variant="outlined"
                       helperText={errors["district"] ? errors["district"] : null}/>
          )}
        />

        <div style={{display: "flex", alignItems: "center"}}>
            <div classes={{ root: classes.textFields }}>
                Active Status
            </div>

            <Switch
            edge="end"
            color="primary"
            onChange={() => setActive(!active)
            }
            checked={active}
            inputProps={{
                "aria-labelledby": "switch-list-label-wifi",
            }}
            />
        </div>

        <Button 
        style={{
            margin: "30px"
            }}
        color="primary" onClick={handleSubmit}>
          {type === 'add-user' ? "Create" : "Update"}
        </Button>
      </div>

      {loading && <Spinner />}
      <Snackbar
        type={snackbarType}
        open={open}
        setOpen={setOpen}
        snackMessage={snackMessage}
      />
    </div>
  );
}

export default AddUser;
