import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../utils/authorizations"
import queryString from "query-string"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== `/login`) {
    navigate(`/login?${queryString.stringify({ next: location.pathname })}`)
    return null
  }
  return <Component {...rest} />
}
export default PrivateRoute
