import Cookies from "js-cookie"


export function parseJwt(token) {
  if (token) {
    const base64Url = token.split(".")[1]
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join("")
    )
    return JSON.parse(jsonPayload)
  }
  return null
}

export function isLoggedIn() {
  if (Cookies.get("access_token_cookie")) {
    return true
  }
  return false
}

export function getUser() {
  if (isLoggedIn()) {
    const token = Cookies.get("access_token_cookie")
    return parseJwt(token)
  }
  return {}
}
