import React, { useEffect, useState } from "react";
import "./requests.css";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { _queryStringify, handleResponse } from "../utils/xhr.js";
import { isEmpty } from '../utils/string.js';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
// import Pagination from '@material-ui/lab/Pagination';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import AddRequest from './addRequest';
import Snackbar from "../shared/Snackbar";
import Spinner from "../shared/Spinner";
import { getUser } from '../utils/authorizations';
import ImageCollageCard from "../components/ImageCollageCard";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
// import Stack from '@material-ui/lab/Stack';
import Cookies from "js-cookie"
import { ThemeProvider, Container } from '@material-ui/core';
import theme from '../theme';
import Header from '../components/Header';
import CopyRight from '../components/CopyRight';
import Link from '../components/Link';


const useStyles = makeStyles((theme) => ({
  textFields: {
    width: "400px",
    margin: "20px 0"
  },
  root: {
    width: '100%',
    marginTop: "20px"
  },
  container: {
    maxHeight: 440,
  },
  borderedTable: {
    width: "50%",
    margin: 20,
    "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)"
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    width: "50%"
  }
}));

const columns = [
  { id: 'water_body_id', label: 'Water Body', minWidth: 170 },
  { id: 'created_at', label: 'Requested_at', minWidth: 170 },
  { id: 'created_by', label: 'Requestee', minWidth: 50 },
  { id: 'status', label: 'Status', minWidth: 50 },
  { id: "update", label: "", minWidth: 70 },
];


function Requests() {
  let user_info = getUser().sub
  const classes = useStyles();
  const [requests, setRequests] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [approvedRejectedrequests, setApprovedRejectedrequests] = useState([]);
  const [requestDetails, setRequestDetails] = useState({'type': 'upload_image', 'status': 'pending', 'data_to_be_updated': []});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(null);
  const [snackbarData, setSnackbarData] = useState({
    snackMessage: "Empty message",
    snackbarType: "",
  });
  const [query, setQuery] = useState("");
  const [pageType, setPageType] = useState("requests");
  const [pendingPage, setPendingPage] = useState(1);
  const [approvedRejectedPage, setApprovedRejectedPage] = useState(1);
  const [request, setRequest] = useState({});
  const [rowOpen, setRowOpen] = useState(null);

  const getDataUpdateRequestsForPending = (page, per_page=perPage) => {
    setLoading(true);
    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-data-update-requests?${_queryStringify({is_pending: "True", page, per_page})}`

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("access_token_cookie")}`,
      }
    })
      .then(handleResponse)
      .then((data) => {
        setPendingRequests(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarData((snackbarData) => ({
          snackMessage: "Sorry, could not get data update requests!",
          snackbarType: "error",
        }));
        setOpen(true);
        console.log(err);
      });
  };

  const getDataUpdateRequestsForApprovedAndRejected = (page, per_page=perPage) => {
    setLoading(true);
    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-data-update-requests?${_queryStringify({is_pending: "False", page, per_page})}`

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("access_token_cookie")}`,
      }
    })
      .then(handleResponse)
      .then((data) => {
        setApprovedRejectedrequests(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarData((snackbarData) => ({
          snackMessage: "Sorry, could not get data update requests!",
          snackbarType: "error",
        }));
        setOpen(true);
        console.log(err);
      });
  };

  const getDataUpdateRequests = () => {
    getDataUpdateRequestsForPending()
    getDataUpdateRequestsForApprovedAndRejected()
  };

  useEffect(() => {
    getDataUpdateRequests();
  }, []);

  const updateRequestStatus = (request_id, status) => {
    setLoading(true);
    let payload = {
        id: request_id,
        status: status
    }

    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/update-data-update-request-status`    

    fetch(url, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${Cookies.get("access_token_cookie")}`,
      },
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then(handleResponse)
      .then((data) => {
        let success_msg = "Request Status Updated Successfully!";
        setLoading(false);
        setSnackbarData((snackbarData) => ({
          snackMessage: success_msg,
          snackbarType: "success",
        }));
        setOpen(true);
        getDataUpdateRequests();

      })
      .catch((err) => {
        let error_msg = "Sorry, failed to update the request status!";
        setSnackbarData((snackbarData) => ({
          snackMessage: error_msg,
          snackbarType: "error",
        }));

        setOpen(true);
        setLoading(false);
        console.log(err);
      });
  };


  const _filterRequests = (request) => {
    var lowerCaseQuery = query.toLowerCase();
    return (request['water_body'].toLowerCase().indexOf (lowerCaseQuery) > -1 || request['created_by_name'].toLowerCase().indexOf (lowerCaseQuery) > -1);
  };

  const renderRequestList = (requests, page, setPage, getDataUpdateRequestsForGivenStatus) => {
    var filteredRequests = requests.filter(_filterRequests);
    if (filteredRequests.length === 0 && page === 1) {
      return (
        <div className={classes.root}
             style={{textAlign: "center"}}>
          No Data!
        </div>
      );
    }

    return (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell />
                {columns.map(renderTableColumns)}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRequests.map(renderUserRow)}
            </TableBody>

            <TableFooter>
              <div style={{display: "flex", flexDirection: "row"}}>
                <IconButton aria-label="next"
                            size="small"
                            onClick={() => {setPage(page - 1); getDataUpdateRequestsForGivenStatus(page-1)}}
                            disabled={page <= 1}
                            >
                  <NavigateBeforeIcon />
                </IconButton>

                <p> Page No. <b>{page} </b></p>

                <IconButton aria-label="next"
                            size="small"
                            onClick={() => {setPage(page + 1); getDataUpdateRequestsForGivenStatus(page+ 1)}}
                            disabled={filteredRequests.length < perPage}
                            >
                  <NavigateNextIcon />
                </IconButton>
              </div>

            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  const renderTableColumns = (column) => {
    return (
      <TableCell
        key={column.id}
        align={column.align}
        style={{ minWidth: column.minWidth }}
        >
        {column.label}
      </TableCell>
    );
  };

  const handleRowOpen = (row) => {
    getRequestDetails(row.id)
    setRowOpen(row.id);
    // setLoading(false)
  };

  const getRequestDetails = (id) => {
    setLoading(true);
    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-data-update-request-details?${_queryStringify({id})}`

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("access_token_cookie")}`,
      }
    })
      .then(handleResponse)
      .then((data) => {
        setRequestDetails(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarData((snackbarData) => ({
          snackMessage: "Sorry, could not get data update request details!",
          snackbarType: "error",
        }));
        setOpen(true);
        console.log(err);
      });
  };

  const handleRowClose = () => {
    setRowOpen(null);
  };

  const renderUserRow = (row) => {
    return (
      <React.Fragment>
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
          <TableCell>
            <IconButton aria-label="expand row"
                        size="small"
                        onClick={() => rowOpen == row.id ? handleRowClose() : handleRowOpen(row)}>
              {rowOpen == row.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            {row['water_body']}
          </TableCell>
          <TableCell>
            {row['created_at']}
          </TableCell>
          <TableCell>
            {row['created_by_name']}
          </TableCell>

          <TableCell>
            {row['status'].toUpperCase()}
          </TableCell>

          <TableCell>
            {/* <EditIcon style={{cursor: "pointer"}}
                      onClick={() => {setPageType('edit-user'); setRequest(row);}}/> */}
            {user_info['all_permissions'].includes('update_request_status') && row['status'] == 'PENDING' ?
              // <Stack direction="row" spacing={2}>
              <div style={{display: 'flex', justifyContent: 'space-around'}} >
                <Button variant="outlined" color="primary" startIcon={<CheckIcon />} onClick={() => updateRequestStatus(row.id, 'approved')}>
                  Approve
                </Button>
                <Button variant="outlined" color="secondary"startIcon={<ClearIcon />} onClick={() => updateRequestStatus(row.id, 'rejected')}>
                  Reject
                </Button>
              </div> :
              null
            }
            
            
          </TableCell>
        </TableRow>
        {renderRequestDetails(row, requestDetails)}
      </React.Fragment>
    );
  };

  const renderRequestDetails = (request, row) => {
    return (
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={rowOpen == request.id} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6"
                          style={{margin: "20px"}}
                          component="div">
                Request Details
              </Typography>
              <Table size="small"
                     className={classes.borderedTable}>
                <TableBody>
                  <TableRow key={request.id}>
                    <TableCell align="center">
                      <b>Water Body</b>
                    </TableCell>
                    <TableCell component="th"
                               scope="row"
                               align="left">
                      {request.water_body}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <b>Requested At</b>
                    </TableCell>
                    <TableCell align="left">
                      {request.created_at}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <b>Status</b>
                    </TableCell>
                    <TableCell align="left">
                      {request.status.toUpperCase()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <b>Requestee</b>
                    </TableCell>
                    <TableCell align="left">
                      {request.created_by_name}
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>

              {renderDataTobeUpdatedTable(row.type, row.data_to_be_updated)}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  };


  const renderDataTobeUpdatedTable = (type, data_to_be_updated) => {
    return (
      <Table size="small"
        className={classes.borderedTable}>
        <TableBody>

          {data_to_be_updated.map(d => 
            <TableRow key={type + d.vertical_id}>
              {/* <TableCell align="center">
                <b>{d.vertical?.category}</b>
              </TableCell> */}
              <TableCell align="center">
                <b>{d.vertical?.name}</b>
              </TableCell>
              <TableCell component="th"
                        scope="row"
                        align="left">
                <ImageCollageCard imageList={[d['image_url']]}/>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    )
  }

  const validNumber = (id, v, error_msg="Please enter valid input") => {
    if (!/^-?\d+\.?\d*$/.test(v)) {
      setErrors((errors) => {
        const tempErrors = {...errors,
                            [id]: error_msg};
        return tempErrors;
      });
      return false;
    }

    setErrors((errors) => {
        const tempErrors = {...errors,
                            [id]: false};
        return tempErrors;
      });
    return true;
  };

  const handleSubmit = (userId) => {
    // initiateBot(userId);
  };

  const renderPageContent = () => {
    if (pageType === 'requests') {
      return (
        <div>
          <div style={{
                 display: "flex",
                 justifyContent:"space-between",
                 width: "90%",
                 margin: "0 auto 0 auto"
               }}>
            <FormControl fullWidth
                         className={classes.formControl}
                         variant="outlined">
              <Input
                id="input-with-icon-adornment"
                placeholder="Search"
                onChange={e => setQuery(e.target.value)}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    />
            </FormControl>
            {user_info?.role === 'admin' ? null : (
              <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={() => setPageType('add-request')}
              >Add Request</Button>
            )}
          </div>
          <div className="requests__heading">
            <h3>Pending Requests</h3>
          </div>
          {renderRequestList(pendingRequests, pendingPage, setPendingPage, getDataUpdateRequestsForPending)}

          <div className="requests__heading">
            <h3>Approved/ Rejected Requests</h3>
          </div>
          {renderRequestList(approvedRejectedrequests, approvedRejectedPage, setApprovedRejectedPage, getDataUpdateRequestsForApprovedAndRejected)}
        </div>
      );
    }
    if (pageType === 'add-request') {
      return (
        <AddRequest
          type="add-request"
          setPageType={setPageType}
          getDataUpdateRequests={getDataUpdateRequests}/>);
    }
    return null;
  };

  const { snackbarType, snackMessage } = snackbarData;
  return (
    <>
    <ThemeProvider theme={theme}>

      <Header isSelectBar={false} isTogglePage={true} page="requests" height="120px"/>

      <Box
          sx={{
          backgroundColor: '#d3d5e5',
          minHeight: '100%',
          pt: 3,
          pb: 1
          }}
          style={{background: '#d3d5e5'}}
      >
          <Container maxWidth={false}>
            <div className="tabelpane__content">
              {renderPageContent()}
              {loading && <Spinner />}
              <Snackbar
                type={snackbarType}
                open={open}
                setOpen={setOpen}

                snackMessage={snackMessage}
              />
            </div>
          </Container>
      </Box>

      <div style={{display: "flex", justifyContent: "center"}}>
          <Link to="/" style={{marginRight: 10}}> Home </Link>
          <CopyRight />
        </div>
    </ThemeProvider>
    </>
  );
}

export default Requests;
