import React, { useEffect, useState } from "react";
import "./requests.css";
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { _queryStringify, handleResponse } from "../utils/xhr.js";
import { isEmpty } from "../utils/string.js";
import Snackbar from "../shared/Snackbar";
import Spinner from "../shared/Spinner";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import Cookies from "js-cookie"
import WaterBody from "../pages/water-body/[id]";


const useStyles = makeStyles((theme) => ({
  textFields: {
    width: "400px",
    margin: "20px 0",
  },
}));

const filter = createFilterOptions();

function AddRequest({
  type,
  request,
  setPageType,
  getDataUpdateRequests
}) {
  const classes = useStyles();
  const [label, setLabel] = useState("");
  const [requestType, setRequestType] = useState(null);
  const [waterBody, setWaterBody] = useState(null);
  const [dataToBeUpdatedList, setDataToBeUpdatedList] = useState([{vertical: null, image: null}]);

  const [requestTypes, setRequestTypes] = useState([]);
  const [waterBodies, setWaterBodies] = useState([]);
  const [verticals, setVerticals] = useState([]);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    snackMessage: "Empty message",
    snackbarType: "",
  });

  useEffect(() => {
    getWaterBodies()
    getVerticals()
  }, [request]);


  const getWaterBodies = () => {
    setLoading(true);
    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-water-bodies-based-on-user`

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("access_token_cookie")}`,
      }
    })
      .then(handleResponse)
      .then((data) => {
        setWaterBodies(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarData((snackbarData) => ({
          snackMessage: "Sorry, could not get water bodies!",
          snackbarType: "error",
        }));
        setOpen(true);
        console.log(err);
      });
  };

  const getVerticals = () => {
    setLoading(true);
    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-verticals`


    fetch(url, {
      method: "GET",
    })
      .then(handleResponse)
      .then((data) => {
        setVerticals(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarData((snackbarData) => ({
          snackMessage: "Sorry, could not get roles!",
          snackbarType: "error",
        }));
        setOpen(true);
        console.log(err);
      });
  };

  const validStringField = (
    field,
    value,
    error_message = "Field is required!"
  ) => {
    if (isEmpty(value)) {
      setErrors((errors) => {
        const tempErrors = { ...errors, [field]: error_message };
        return tempErrors;
      });
      return false;
    } else {
      setErrors((errors) => {
        const tempErrors = { ...errors, [field]: false };
        return tempErrors;
      });
      return true;
    }
  };

  const validateDataToBeUpdatedList = () => {
    let correctFilledRows = dataToBeUpdatedList.filter(d => (d['vertical'] !== null && d['image'] !== null))
    let inCorrectRows = dataToBeUpdatedList.filter(d => (d['vertical'] === null && d['image'] !== null) || (d['vertical'] !== null && d['image'] === null))
    let flag = inCorrectRows.length === 0 && correctFilledRows.length > 0
    if(flag === false){
      setSnackbarData((snackbarData) => ({
        snackMessage: "Select the vertical and image!",
        snackbarType: "error",
      }));
      setOpen(true);
    }
    return flag
  }

  const addRequest = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('water_body_id', waterBody.water_body_id);
    formData.append('label', label);
    formData.append('type', 'upload_photos');
    dataToBeUpdatedList.forEach(d => {
      if(d['image'] !== null && d['vertical'] !== null){
        formData.append('data_to_be_updated',
                        d['image'], 
                        d['vertical']['id'] );
      }
    })

    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/create-data-update-request`

    fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${Cookies.get("access_token_cookie")}`,
      }
    })
      .then(handleResponse)
      .then((data) => {
        let success_msg = type === 'add-request' ? "Request has been added!" : "Request details has been updated!";
        setLoading(false);
        setSnackbarData((snackbarData) => ({
          snackMessage: success_msg,
          snackbarType: "success",
        }));
        setOpen(true);
        setPageType('requests');
        getDataUpdateRequests()
      })
      .catch((err) => {
        let error_msg = type === 'add-request' ? "Sorry, failed to add the request!" : "Sorry, failed to update the request!";
        setSnackbarData((snackbarData) => ({
          snackMessage: error_msg,
          snackbarType: "error",
        }));

        setOpen(true);
        setLoading(false);
        console.log(err);
      });
  };

  const handleSubmit = () => {
    if (validDetails()) {
      addRequest();
    }
    //history.push("/dashboard");
  };

  const validDetails = () => {
    const validWaterBody = validStringField("water_body", waterBody?.water_body_id);
    const validDataToBeUpdatedList = validateDataToBeUpdatedList();
    return ( validWaterBody && validDataToBeUpdatedList);
  };

  const setVerticalForIndex = (index, vertical) => {
    // console.log("vertical", index)
    // 1. Make a shallow copy of the items
    let dataToBeUpdatedListNew = [...dataToBeUpdatedList];
    // 2. Make a shallow copy of the item you want to mutate
    let dataToBeUpdatedIndexValue = {...dataToBeUpdatedListNew[index]};
    // 3. Replace the property you're intested in
    dataToBeUpdatedIndexValue.vertical = vertical;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    dataToBeUpdatedListNew[index] = dataToBeUpdatedIndexValue;
    // 5. Set the state to our new copy
    setDataToBeUpdatedList(dataToBeUpdatedListNew)
  }

  const setImageFileForIndex = (index, imageFile) => {
    // console.log("image", index)
    // 1. Make a shallow copy of the items
    let dataToBeUpdatedListNew = [...dataToBeUpdatedList];
    // 2. Make a shallow copy of the item you want to mutate
    let dataToBeUpdatedIndexValue = {...dataToBeUpdatedListNew[index]};
    // 3. Replace the property you're intested in
    dataToBeUpdatedIndexValue.image = imageFile;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    dataToBeUpdatedListNew[index] = dataToBeUpdatedIndexValue;
    // 5. Set the state to our new copy
    setDataToBeUpdatedList(dataToBeUpdatedListNew)
  }

  const renderDataToBeUpdated = (index, dataToBeUpdated) => {
    return (
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Autocomplete
          filterSelectedOptions
          id="vertical"
          options={verticals}
          getOptionLabel={(option) => option.name}
          value={dataToBeUpdated.vertical ? dataToBeUpdated.vertical : null}
          onChange={(event, newValue) => {
            if(newValue){
                setVerticalForIndex(index, newValue);
            }
          }}
          className="business__autocomplete"
          renderInput={(params) => (
            <TextField {...params}
                      error={errors["vertical"] ? true : null}
                      label="Select Vertical"
                      variant="outlined"
                      helperText={errors["vertical"] ? errors["vertical"] : null}/>
          )}
        />

        <div>
          <input
            key={"image_"+ index}
            accept="image/*"
            className={classes.input}
            style={{ display: 'none' }}
            id={"raised-button-file-"+index}
            type="file"
            onChange={e => setImageFileForIndex(index, e.target.files[0])}
          />
          <label htmlFor={"raised-button-file-"+index} key={"image_label_"+ index}>
            <Button style={{margin: "30px"}} color="primary" component="span" className={classes.button}>
              Select Image
            </Button>
          </label> 
        </div>
        <div className="file-name" >
          {dataToBeUpdated.image ? dataToBeUpdated.image.name : null}
        </div>

     </div>
  )
  }

  const addRowInDateToBeUpdatedList = () => {
    if(dataToBeUpdatedList.length < 5) {
      let dataToBeUpdatedListNew = [...dataToBeUpdatedList];
      dataToBeUpdatedListNew.push({vertical: null, image: null})
      setDataToBeUpdatedList(dataToBeUpdatedListNew)
    } else {
      setSnackbarData((snackbarData) => ({
        snackMessage: "Sorry, maximum 5 photos can be added!",
        snackbarType: "error",
      }));
      setOpen(true);
    }
    
  }

  const { snackbarType, snackMessage } = snackbarData;
  return (
    <div className="tabelpane__content">
      <div style={{display: "flex", justifyContent: "flex-start", cursor: "pointer"}}
           onClick={() => {setPageType("requests"); getDataUpdateRequests();}}>
        <ArrowBackIcon /> Back
      </div>
      <div className="requests__heading">
        <h3>
          {type === 'add-request' ? "Create a Request" : "Update Request Details"}
        </h3>
      </div>

      <div className="requests__questions">
        <TextField
          error={errors["label"] ? true : null}
          id="label"
          label="Description"
          variant="outlined"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          classes={{ root: classes.textFields }}
          helperText={errors["label"] ? errors["label"] : null}
        />

        <Autocomplete
          filterSelectedOptions
          id="water_body"
          options={waterBodies}
          getOptionLabel={(option) => option.name}
          value={waterBody ? waterBody : null}
          onChange={(event, newValue) => {
            if(newValue){
                setWaterBody(newValue);
            }
          }}
          className="business__autocomplete"
          renderInput={(params) => (
            <TextField {...params}
                       error={errors["water_body"] ? true : null}
                       label="Select Water Body"
                       variant="outlined"
                       helperText={errors["water_body"] ? errors["water_body"] : null}/>
          )}
        />

        {dataToBeUpdatedList.map((d, i) => 
          renderDataToBeUpdated(i, d)
        )}
        <Button style={{margin: "30px"}} color="secondary" component="span" className={classes.button} onClick={addRowInDateToBeUpdatedList}>
          <AddIcon /> Add More
        </Button>

        <Button 
        style={{margin: "30px"}}
        color="primary" onClick={handleSubmit}>
          {type === 'add-request' ? "Create" : "Update"}
        </Button>
      </div>

      {loading && <Spinner />}
      <Snackbar
        type={snackbarType}
        open={open}
        setOpen={setOpen}
        snackMessage={snackMessage}
      />
    </div>
  );
}

export default AddRequest;
